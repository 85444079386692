import React from "react"
import { Box, Link, Typography } from "@material-ui/core"
import { graphql, useStaticQuery } from "gatsby"

const Contact = () => {
  const data = useStaticQuery(graphql`
    query SiteContactQuery {
      site {
        siteMetadata {
          name
          personalTitle
          email
        }
      }
    }
  `)

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      height="100%"
    >
      <Box>
        <Typography>
          {data.site.siteMetadata.name}
          <br />
          {data.site.siteMetadata.personalTitle}
          <br />
          Email: <Link variant="inherit">{data.site.siteMetadata.email}</Link>
        </Typography>
      </Box>
    </Box>
  )
}
export default Contact
